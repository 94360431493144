import "./servicenavigation.scss"
import { useLocation } from "@reach/router"
import { Link, useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"


const ServiceNavigation = ({ className }) => {
  const { t } = useTranslation("common", { keyPrefix: "header" })
  const location = useLocation()
  return (
    <section
      className={(className ? className + " " : "") + "service-navigation"}
    >
      <div className="navigation-container">
        <Link to={"/services/"} className={location.pathname.includes('/services') && 'selected'} >{t("Services")}</Link>
        <Link to={"/products/"} className={location.pathname.includes('/products') && 'selected'} >{t("Products")}</Link>
        <Link to={"/qaservices/"} className={location.pathname.includes('/qaservices') && 'selected'} >{t("QA offering")}</Link>
      </div>
    </section>
  )
}

export default ServiceNavigation
