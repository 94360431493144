import * as React from "react"
//import { Link } from "gatsby"
//import { StaticImage } from "gatsby-plugin-image"

import FindDreamJob from "../components/careers/finddreamjob"
import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import "./mainpage.scss"

import { graphql } from "gatsby"
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next"
import HeaderProducts from "../components/products/header-pro"
import OurProducts from "../components/products/ourproducts"
import AboutYou from '../components/careers/aboutyou'

const ProductsPage = () => {
    const { i18n } = useI18next()
    const { t } = useTranslation()
    return (
        <Layout headerTransparent={true} lang={i18n.resolvedLanguage}>
            <Seo title={t("title", { ns: 'products' })} lang={i18n.resolvedLanguage} description={t("seo.description", { ns: 'common' })} />
            <div id="products-container" className="max-container-pages">
                <HeaderProducts></HeaderProducts>
                <OurProducts></OurProducts>
                {/* <FindDreamJob></FindDreamJob> */}
                <AboutYou></AboutYou>

            </div>
        </Layout>
    )
}

export default ProductsPage
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "products"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
